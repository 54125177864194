// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background-color: #fff;
  color: #333;
}


html {
  scroll-behavior: smooth;
}

.div {
scroll-margin-top:4rem;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,gCAAgC;EAChC,sBAAsB;EACtB,WAAW;AACb;;;AAGA;EACE,uBAAuB;AACzB;;AAEA;AACA,sBAAsB;AACtB","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Arial\", sans-serif;\n  background-color: #fff;\n  color: #333;\n}\n\n\nhtml {\n  scroll-behavior: smooth;\n}\n\n.div {\nscroll-margin-top:4rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
